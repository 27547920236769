@import "../../Variables";

i {
    color: $brand-primary !important;
    font-size: 40px !important;
}

.services-heading {
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 2rem;
}

.services-hr {
    border-top: 5px solid $brand-secondary;
    text-align: center;
    margin: auto;
    padding-top: 2rem;
    width: 10%;
}
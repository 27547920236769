@import "../../Variables";

i {
    color: $brand-primary !important;
    font-size: 60px !important;
    font-weight: bold;
    margin-bottom: 30px !important;
}

.process-hr {
    border-top: 5px solid $brand-secondary;
    text-align: center;
    margin: auto;
    padding-top: 2rem;
    width: 10%;
}

.process-title {
    margin-bottom: 2rem;
    font-size: 1.5rem;
    font-weight: bold;
    color: $accent2;
}

.process-step {
    text-align: center;
    margin-bottom: 2rem;
}
@import "../../Variables";

.hero-section {
    position: relative;
    background: url('../../Assets/Images/pexels-startup-stock-photos-7070.jpg') no-repeat center center/cover;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0 2rem;
    box-sizing: border-box;

    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(11, 18, 31, 0.9);
        /* Opaque overlay color */
    }

    .hero-content {
        position: relative;
        z-index: 1;
        max-width: 600px;
        color: #fff;

        h1 {
            font-size: 3rem;
            font-weight: bold;
            margin-bottom: 1.5rem;
        }
    }
}

.hero-hr {
    border-top: 5px solid $brand-secondary !important;
    text-align: center;
    margin: auto;
    padding-top: 2rem;
    width: 10%;
    color: $brand-secondary !important;
}
@import "../../Variables";

.approach-section {
    text-align: center;
    padding: 4rem 4rem 4rem 4rem;
    background-color: $light-bg;
    color: $brand-dark;
}

.approach-hr {
    border-top: 5px solid $accent1;
    text-align: center;
    margin: auto;
    padding-top: 2rem;
    width: 10%;
}

.approach-attention {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 2rem;
}
@import "../../Variables";

.alt-body {
    color: $brand-dark;
    font-weight: bold;
    font-size: 1.2rem;
}

.alt-subHeading {
    color: $brand-dark;
    font-weight: bold;
    font-size: 1.5rem;
    margin-bottom: 2rem;
}
@import '../../Variables';

.services-section {
    padding: 4rem 2rem;
    background-color: $light-bg;
    text-align: center;

    .section-title {
        margin-bottom: 2rem;
        font-size: 2.5rem;
        font-weight: bold;
    }

    .section-description {
        margin-bottom: 4rem;
        color: $brand-dark;
    }

    .service-card {
        background-color: $white-bg;
        color: $brand-dark;
        padding: 2rem;
        margin-bottom: 2rem;
        border-radius: .5rem;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        transition: transform 0.3s ease-in-out;
        display: flex;
        flex-direction: column;

        &:hover {
            transform: translateY(-5px);
            box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
        }

        .icon-container {
            font-size: 3rem;
            color: $brand-primary;
            margin-bottom: 1rem;
        }

        h3 {
            margin-top: 0;
            margin-bottom: 1rem;
            color: $accent1;
        }

        p {
            flex: 1;
            margin: 0;
        }

        a {
            color: $brand-secondary;
            font-weight: bold;
            text-decoration: none;
            transition: color 0.3s ease-in-out;

            &:hover {
                color: darken($brand-secondary, 10%);
            }
        }
    }
}
@import '../../Variables';

.contact-page {
    .form-group {
        margin-bottom: 1.5rem;
    }

    .form-control {
        border-radius: 0.25rem;
        padding: 0.5rem 1rem;
        border: 1px solid #ccc;
    }

    .form-check {
        margin-bottom: 0.5rem;
    }

    .form-check-input {
        margin-right: 0.5rem;
    }

    .btn-primary-custom {
        background-color: $brand-primary;
        border-color: $brand-primary;
        color: #000;

        &:hover {
            background-color: darken($brand-primary, 10%);
        }
    }
}
@import '../../Variables';

.navbar {
    padding-left: 4rem;
    padding-right: 4rem;
    background-color: #0b121f;
}

@media screen and (max-width: 992px) {
    .navbar {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}

#brand-logo {
    max-width: 275px;

    @media screen and (min-width: 992px) {
        max-width: 350px;
    }
}

.nav-link {
    font-size: 18px;
    color: #FFFF !important;

    &:hover {
        color: $brand-secondary !important;
    }
}

.nav-link.active {
    border-bottom: 1.5px solid $brand-secondary;
}

.hamburger-react {
    div {
        height: 2px !important;
    }

    color: $brand-secondary;
}